import React from "react";
import CommonHeading from "../components/common/CommonHeading";


export default function TermsAndConditions() {
  return (
    <div className="container">
            <CommonHeading heading="Terms & Conditions" />

      <h3>Terms & Conditions</h3>
      <p>
        It is mandatory to provide Govt. ID card during check-in at hotel/
        homestay. Pan Card is not allowed as an ID card as it doesn’t mention the
        address.
      </p>
      <p>
        The above mentioned rates are subject to guaranteed number of people as
        mentioned above. Decreasing of rooms or guests will affect rates on a
        conditional basis.
      </p>
      <p>
        In case of an unmarried couple, the guest’s age should be above 18.
        Property holds the right to deny if the guest is coming with a local ID
        card.
      </p>
      <p>
        For children, the property holds the right to ask for a valid ID card as
        age proof.
      </p>
      <p>
        In case of any natural calamity or political instability, the advance
        amount won’t be refunded. The amount will be adjusted in the same
        property within 1 year from the prior check-in date.
      </p>
      <p>
        In case of non-fulfillment of rooms other than (Natural Calamity, Acts of
        God, Government imposed Lockdown, Restrictions, War), Say Rooms & Homes
        will arrange another property in a similar budget. If the alternative
        property is in a lower budget, Say Rooms & Homes will refund the
        adjustment. If the guest refuses to check-in to the alternative property,
        Say Rooms & Homes will refund 100% of the advance paid.
      </p>
      <p>You must make all payments 5 days prior to check-in.</p>
      <p>
        If booking is cancelled, the refund will be processed within 14 working
        days (excluding Saturday, Sunday, Government Holiday) from the
        cancellation request. Deduction will be calculated on the total Booking
        value.
      </p>

      <h5>Cancellation Policy (Not Applicable for Non-Refundable Bookings)</h5>
      <ul>
        <li>Non-Refundable for cancellation done till 48 hrs before the check-in date.</li>
        <li>Any cancellation done between 3-6 days before check-in will be charged 75% of the total amount.</li>
        <li>Any cancellation done between 7-10 days before check-in will be charged 50% of the total amount.</li>
        <li>Any cancellation done between 11-15 days before check-in will be charged 25% of the total amount.</li>
        <li>Any cancellation done 15 days before check-in will be refunded 100% of the total amount except the payment gateway charges.</li>
        <li>
          Non-Refundable for cancellation done due to any political unrest,
          natural calamities, or any restrictions imposed by the Government.
        </li>
      </ul>

      <h5>Room Allocation Policy</h5>
      <p>
        Room allocation in any hotel/homestay is subject to availability and
        first-come-first-serve basis. Any requests for changes can be made as per
        availability.
      </p>

      <h5>Refund Policy</h5>
      <ul>
        <li>
          In case of any cancellation, the refund shall be processed in 15
          working days in the bank account of the guest (Excluding Bank Holidays)
          after deduction of Payment Gateway Charges (If Any).
        </li>
        <li>
          In case the guest doesn't want a cash refund, the amount can be kept as
          a credit for future bookings with a validity of 365 days.
        </li>
      </ul>
    </div>
  );
}
