import React, { useState } from 'react';
import { cities } from '../data/Data';
import { useNavigate } from 'react-router-dom';
import './city.css';

const CityDropdown = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const navigate = useNavigate(); // To programmatically navigate

  // Toggle dropdown visibility
  const toggleCityDropdown = (cityName) => {
    if (selectedCity === cityName) {
      setSelectedCity(null); // Collapse if already open
    } else {
      setSelectedCity(cityName); // Open selected city dropdown
    }
  };

  // Handle location click to navigate to the child page
  const handleLocationClick = (cityName) => {
    navigate(`/city/${cityName}`); // Redirect to child page with city name
  };

  return (
    <div className="city-dropdown-row">
      {cities.map((city, index) => (
        <div key={index} className="city">
          <button
            className="city-button"
            onClick={() => toggleCityDropdown(city.name)}
          >
            {city.name} ▼
          </button>
          {/* Show locations if this city is selected */}
          {selectedCity === city.name && (
            <div className="locations-dropdown">
              {city.locations.map((location, locIndex) => (
                <a
                  key={locIndex}
                  className="location"
                  onClick={() => handleLocationClick(city.name)}  // Pass the city name to child page
                >
                  {location}
                </a>
              ))}
            </div>
          )}
        </div>
      ))}
      {/* Add "All Cities" option */}
      <div className="city all-cities">
        <a href="/all-cities" className="all-cities-button">
          All Cities →
        </a>
      </div>
    </div>
  );
};

export default CityDropdown;
