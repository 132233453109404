import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './SearchComponent.css';
import { useNavigate } from 'react-router-dom';

const Book = () => {
  const [destination, setDestination] = useState('');
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (!destination) {
      alert('Please enter a location before searching.');
      return;
    }

    console.log({
      destination,
      checkInDate,
      checkOutDate,
      adults,
      children,
      rooms,
    });

    navigate('/hotel');
  };

  const handleIncrement = (setter, value) => setter(value + 1);
  const handleDecrement = (setter, value) => {
    if (value > 0) setter(value - 1);
  };

  return (
    <div className="container sticky-search my-4 d-none d-md-block"> {/* Hide the search bar in mobile view */}
      <div className="row g-2 align-items-center border border-warning rounded p-3">
        
        {/* Destination Input */}
        <div className="col-lg-3 col-md-12 col-12 mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Search by city, hotel, or neighborhood"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            style={{ fontFamily: 'Arial, sans-serif' }}
          />
        </div>

        {/* Check-in Date */}
        <div className="col-lg-2 col-md-6 col-12 mb-2">
          <div className="input-group">
            <DatePicker
              selected={checkInDate}
              onChange={(date) => setCheckInDate(date)}
              className="form-control"
              placeholderText="Check-in"
            />
          </div>
        </div>

        {/* Check-out Date */}
        <div className="col-lg-2 col-md-6 col-12 mb-2">
          <div className="input-group">
            <DatePicker
              selected={checkOutDate}
              onChange={(date) => setCheckOutDate(date)}
              className="form-control"
              placeholderText="Check-out"
            />
          </div>
        </div>

        {/* Guest and Room Dropdown */}
        <div className="col-lg-3 col-md-12 col-12 mb-2">
          <div className="dropdown w-100">
            <button
              className="btn btn-light border w-100 d-flex justify-content-between align-items-center"
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {`${rooms} Room, ${adults} Guest${adults > 1 ? 's' : ''}`}
              <i className="fas fa-chevron-down"></i>
            </button>
            {showDropdown && (
              <div className="dropdown-menu p-3 w-100 show">
                {/* Adults */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span>Adults</span>
                  <div className="d-flex align-items-center">
                    <button 
                      className="btn btn-secondary btn-sm" 
                      onClick={() => handleDecrement(setAdults, adults)}
                    >
                      -
                    </button>
                    <span className="mx-2">{adults}</span>
                    <button 
                      className="btn btn-secondary btn-sm" 
                      onClick={() => handleIncrement(setAdults, adults)}
                    >
                      +
                    </button>
                  </div>
                </div>

                {/* Children */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span>Children</span>
                  <div className="d-flex align-items-center">
                    <button 
                      className="btn btn-secondary btn-sm" 
                      onClick={() => handleDecrement(setChildren, children)}
                    >
                      -
                    </button>
                    <span className="mx-2">{children}</span>
                    <button 
                      className="btn btn-secondary btn-sm" 
                      onClick={() => handleIncrement(setChildren, children)}
                    >
                      +
                    </button>
                  </div>
                </div>

                {/* Rooms */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span>Rooms</span>
                  <div className="d-flex align-items-center">
                    <button 
                      className="btn btn-secondary btn-sm" 
                      onClick={() => handleDecrement(setRooms, rooms)}
                    >
                      -
                    </button>
                    <span className="mx-2">{rooms}</span>
                    <button 
                      className="btn btn-secondary btn-sm" 
                      onClick={() => handleIncrement(setRooms, rooms)}
                    >
                      +
                    </button>
                  </div>
                </div>

                <button className="btn btn-primary w-100" onClick={() => setShowDropdown(false)}>
                  Done
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Search Button */}
        <div className="search-button-container col-lg-2 col-md-12 col-12 mb-2">
  <button className="btn btn-success fw-bold w-100" onClick={handleSearch}>
    Search
  </button>
</div>

      </div>
    </div>
  );
};

export default Book;
