import React, { useState } from 'react';
import CommonHeading from "../common/CommonHeading";

const OffersPage = () => {
  const [expandedImage, setExpandedImage] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false); // New state to track zoom

  const toggleImageExpand = (imageId) => {
    if (expandedImage === imageId) {
      setExpandedImage(null);
    } else {
      setExpandedImage(imageId);
      setActiveIndex(0);
      setIsZoomed(false); // Reset zoom when new image is opened
    }
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const getWhatsAppLink = (altText) => {
    const phoneNumber = "9382167169";
    const message = `May I inquire about the ${altText} package? 😊`;
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const images = [
    { id: 1, src: '../assets/img/offers1.jpg', alt: 'Darjeeling 2 Night | 3 days' },
    { id: 2, src: '../assets/img/offers2.jpg', alt: 'Darjeeling & Dooars 3 Night | 4 days' },
    { id: 3, src: '../assets/img/offers3.jpg', alt: 'Darjeeling' },
    { id: 4, src: '../assets/img/offers4.jpg', alt: 'Ganktok & Darjeeling 4 Night | 5 days' },
    { id: 5, src: '../assets/img/offers5.jpg', alt: 'Darjeeling & Kalimpong 3 Night | 4 days' },
  ];

  const additionalImages = {
    1: [
      { id: 6, src: '../assets/img/offers1.jpg', alt: 'Offers 1' },
      { id: 7, src: '../assets/img/offer1.1.jpg', alt: 'Offers 1.1' },
      { id: 8, src: '../assets/img/offer1.2.jpg', alt: 'Offers 1.2' },
      { id: 9, src: '../assets/img/offer1.3.jpg', alt: 'Offers 1.3' },
    ],
    2: [
      { id: 10, src: '../assets/img/offers2.jpg', alt: 'Offers 2' },
      { id: 11, src: '../assets/img/offer2.1.jpg', alt: 'Offers 2.1' },
      { id: 12, src: '../assets/img/offer2.2.jpg', alt: 'Offers 2.2' },
      { id: 13, src: '../assets/img/offer2.3.jpg', alt: 'Offers 2.3' },
      { id: 14, src: '../assets/img/offer2.4.jpg', alt: 'Offers 2.4' },
    ],
    3: [
      { id: 15, src: '../assets/img/offers3.jpg', alt: 'Offers 3' },
    ],
    4: [
      { id: 16, src: '../assets/img/offers4.jpg', alt: 'Offers 4' },
    ],
    5: [
      { id: 17, src: '../assets/img/offers5.jpg', alt: 'Offers 5' },
    ],
  };

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <CommonHeading heading="Our Offers" />
        <div className="row justify-content-center">
          <div className="d-flex flex-wrap justify-content-center g-3">
            {images.map((image) => (
              <div key={image.id} className="offer-item p-2">
                <div className="rounded shadow overflow-hidden text-center h-100 d-flex flex-column">
                  <div className="position-relative">
                    <img
                      className="img-fluid"
                      src={image.src}
                      alt={image.alt}
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleImageExpand(image.id)}
                    />
                  </div>
                  <div className="p-4 mt-auto">
                    <h5 className="fw-bold mb-3">{image.alt}</h5>
                    <a
                      className="btn btn-primary"
                      href={getWhatsAppLink(image.alt)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {expandedImage && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1050,
          }}
        >
          <div className="w-75 h-75">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {additionalImages[expandedImage].map((addImage, index) => (
                  <div
                    key={addImage.id}
                    className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                  >
                    <img
                      src={addImage.src}
                      alt={addImage.alt}
                      className={`img-fluid d-block mx-auto ${isZoomed ? 'zoomed' : ''}`}
                      style={{
                        borderRadius: '8px',
                        maxHeight: '60vh', // Increased the height of the popup image
                        maxWidth: '100%',
                        objectFit: 'contain',
                        cursor: 'zoom-in',
                      }}
                      onClick={toggleZoom} // Toggle zoom on click
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                onClick={() =>
                  handleSelect(
                    (activeIndex - 1 + additionalImages[expandedImage].length) %
                    additionalImages[expandedImage].length
                  )
                }
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                onClick={() =>
                  handleSelect((activeIndex + 1) % additionalImages[expandedImage].length)
                }
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={() => toggleImageExpand(null)}
            style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '50%' }}
          />
        </div>
      )}

      <style jsx>{`
        .offer-item {
          flex: 1 1 calc(20% - 1rem);
          margin: 0.5rem;
        }
          /* Zoom out effect on hover */
  .offer-item:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
        .offer-item .rounded {
          height: 100%; /* Ensure the card takes full height */
        }
        .zoomed {
          transform: scale(2); /* Increase the size of the image */
    transition: transform 0.3s ease; /* Smooth transition */
    cursor: zoom-out; /* Change the cursor to indicate zoom-out */
        }
        @media (max-width: 768px) {
          .offer-item {
            flex: 1 1 calc(50% - 1rem); /* Stack two images in smaller screens */
          }
        }
      `}</style>
    </div>
  );
};

export default OffersPage;
