import React from "react";
import CommonHeading from "../components/common/CommonHeading";


export default function PrivacyPolicy() {
  return (
    <div className="container">
            <CommonHeading heading="Privacy Policy" />

      <h4>INTRODUCTION</h4>
      <p>
        Say Rooms & Homes values the privacy of its users and is committed to
        protecting the confidentiality of information provided by its users as a
        responsible data controller and processor. This Privacy Policy outlines
        the practices for handling and securing users' Personal Information by
        Say Rooms & Homes and its subsidiaries and affiliates.
      </p>
      <p>
        This Privacy Policy applies to any person ("User") who books, intends to
        book, or inquires about any accommodation or service(s) offered by Say
        Rooms & Homes through any of its customer interface channels, including
        its website, mobile site, mobile app, and offline channels such as call
        centres and offices (collectively referred to as "Booking Channels").
      </p>
      <p>
        In this Privacy Policy, "you" or "your" refers to the User, and "we",
        "us", "our" refers to Say Rooms & Homes. "Website" encompasses Say Rooms
        & Homes' website(s).
      </p>
      <p>
        By using or accessing the Website, you agree to the terms of this
        Privacy Policy. If you disagree with this Privacy Policy, please refrain
        from using or accessing our Website or other Booking Channels.
      </p>
      <p>
        This Privacy Policy does not apply to third-party websites, mobile
        sites, or mobile apps, even if they are linked to our Website. Users
        should be aware that the information and privacy practices of Say Rooms
        & Homes business partners, advertisers, sponsors, or other sites linked
        from our Website may differ significantly from this Privacy Policy. We
        recommend reviewing the privacy statements and policies of any third
        parties with whom you interact.
      </p>
      <p>
        This Privacy Policy is an integral part of your User Agreement with Say
        Rooms & Homes. All capitalized terms used but not defined herein shall
        have the meanings ascribed to them in the User Agreement.
      </p>
      <p>
        Say Rooms & Homes is dedicated to maintaining the highest standards of
        privacy and data protection for all its users. We strive to ensure that
        your personal information is handled with the utmost care and respect.
      </p>

      <h4>TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS</h4>
      <p>
        Say Rooms & Homes collects certain information to provide our services
        and fulfill our legal and contractual obligations as outlined in our
        User Agreement. "Personal Information" includes data you provide when
        using our services, such as:
      </p>
      <ul>
        <li>Account Registration:</li>
        <ul>
          <li>Personal details: Name, Gender, Age, etc.</li>
          <li>Contact information: Email, Postal Address, Phone Number</li>
          <li>Financial information: Banking Details, Payment History</li>
          <li>Profile information: Pictures, Lifestyle Preferences</li>
        </ul>
        <li>Additional Information:</li>
        <ul>
          <li>Booking History and Preferences</li>
          <li>Login Credentials</li>
          <li>Files you choose to store on our servers</li>
          <li>
            Publicly available data or information from linked social media
            accounts
          </li>
          <li>Information about other guests included in your bookings</li>
          <li>
            Documentation required for specific services (e.g., contactless
            check-in)
          </li>
          <li>Government-issued ID details for certain bookings or services</li>
          <li>
            Travel details like origin/destination and residency information
          </li>
          <li>
            Health-related information if required for specific services or
            situations
          </li>
        </ul>
      </ul>
      <p>
        Say Rooms & Homes will use this information strictly for the specified
        purposes. We may share necessary details with service providers or third
        parties to facilitate your booking. We take care to redact sensitive
        information from documents when processing your requests.
      </p>
      <p>
        If you choose not to provide required information or request its
        deletion, we may be unable to process your booking or provide certain
        services.
      </p>
      <p>
        Say Rooms & Homes will not share your personal information without your
        consent unless required by law enforcement, court order, or legal
        process.
      </p>
      <p>
        We respect your privacy and aim to collect only the information
        necessary to provide you with the best possible service and experience
        when using Say Rooms & Homes.
      </p>

      <h4>HOW WE USE YOUR PERSONAL INFORMATION</h4>
      <p>
        Say Rooms & Homes may use your Personal Information in the following
        ways:
      </p>
      <ul>
        <li>During Booking:</li>
        <p>
          We use your payment details (including securely encrypted card
          information), traveller information linked to your account, and other
          relevant data to facilitate quick and efficient bookings.
        </p>
        <li>General Usage:</li>
        <p>Your Personal Information helps us to:</p>
        <ul>
          <li>Confirm reservations with our partners</li>
          <li>Update you on booking status</li>
          <li>Send confirmations via SMS, messaging apps, or email</li>
          <li>Notify you of any changes to your reservations</li>
          <li>Enable our customer support to assist you</li>
          <li>Personalize your experience on our platforms</li>
          <li>Request feedback on our services</li>
          <li>Verify your account and prevent misuse</li>
          <li>Offer birthday or anniversary specials</li>
        </ul>
      </ul>

      {/* Add other sections as needed based on the content you shared */}

      <p>
        For any concerns about our Privacy Policy, please email at
        booking@sayrooms.com.
      </p>
    </div>
  );
}
