import React from "react";
import Heading from "../common/Heading";
import { about } from "../data/Data";

export default function About() {
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                About Us
              </h6>
              <h1 className="mb-4">
                Welcome to{" "}
                <span className="text-primary text-uppercase">SAY Rooms & Homes</span>
              </h1>
              <p className="mb-4">
                SAY Rooms & Homes is a travel start-up founded by an experienced
                team from travel and hospitality industry based out of Gurugram,
                Kolkata, and Siliguri. SAY is a platform that addresses the
                challenges faced by travelers when searching for secure and clean
                accommodations at reasonable prices in remote and exotic
                destinations. Through the use of deep knowledge about the
                industry and tech, we aim to tackle the logistical and operational
                difficulties faced by homestays and small hotels.
              </p>
              <div className="row g-3 pb-4">
                {about.map((item, key) => (
                  <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s" key={key}>
                    <div className="border rounded p-1">
                      <div className="border rounded text-center p-4">
                        {item.icon}
                        <h2 className="mb-1" data-toggle="counter-up">
                          {item.count}
                        </h2>
                        <p className="mb-0">{item.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <a className="btn btn-primary fw-bold py-3 px-5 mt-2" href="">
                Explore More
              </a>
            </div>

            <div className="col-lg-6" align-center>
              <div className="image-grid">
                <div className="image-wrapper">
                  <img
                    className="img-fluid rounded"
                    src="/assets/img/about-1.jpg"
                    alt="About 1"
                  />
                </div>
                <div className="image-wrapper">
                  <img
                    className="img-fluid rounded"
                    src="/assets/img/about-2.jpg"
                    alt="About 2"
                  />
                </div>
                <div className="image-wrapper">
                  <img
                    className="img-fluid rounded"
                    src="/assets/img/about-3.jpg"
                    alt="About 3"
                  />
                </div>
                <div className="image-wrapper">
                  <img
                    className="img-fluid rounded"
                    src="/assets/img/about-4.jpg"
                    alt="About 4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
