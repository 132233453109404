import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { roomItems } from "../data/Data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal, Button } from "react-bootstrap";
import CityDropdown from "./city"; // Import the CityDropdown component
import "./cityhotels.css"; // Custom CSS for layout

const CityHotels = () => {
  const { cityName } = useParams();

  // Filter the roomItems based on the city name
  const cityHotels = roomItems.filter((room) =>
    room.name.toLowerCase().includes(cityName.toLowerCase())
  );

  // State to manage the main image for each hotel
  const [mainImages, setMainImages] = useState(
    cityHotels.map((hotel) => hotel.images[0])
  );

  // Function to handle thumbnail click
  const handleThumbnailClick = (index, image) => {
    const updatedMainImages = [...mainImages];
    updatedMainImages[index] = image;
    setMainImages(updatedMainImages);
  };

  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (item) => {
    setModalContent(item);
    setShowModal(true);
  };

  const getWhatsAppLink = (hotelName) => {
    return `https://api.whatsapp.com/send?phone=9382167169&text=I would like to inquire about booking a room at ${hotelName}`;
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="city-hotels-container">
        {/* Left Sidebar for City Dropdown */}
        <div className="city-dropdown-sidebar">
          <CityDropdown />
        </div>

        {/* Right Section for Hotels List */}
        <div className="hotel-list-section">
          <h2>Hotels in {cityName}</h2>
          <div className="hotel-cards">
            {cityHotels.length > 0 ? (
              cityHotels.map((hotel, index) => (
                <div className="hotel-card-horizontal" key={index}>
                  <div className="image-gallery">
                    {/* Main Image on the Left */}
                    <div className="main-image-horizontal">
                      <img
                        src={mainImages[index]} // Use the dynamic main image
                        alt="Main"
                        className="large-image-horizontal"
                      />
                    </div>

                    {/* Thumbnails on the Right */}
                    <div className="thumbnails-right">
                      {hotel.images.slice(0, 5).map((img, imgIndex) => (
                        <img
                          key={imgIndex}
                          src={img}
                          alt={`Thumbnail ${imgIndex + 1}`}
                          className="thumbnail-horizontal"
                          onClick={() => handleThumbnailClick(index, img)} // Handle click event
                        />
                      ))}
                    </div>
                  </div>

                  {/* Hotel details section */}
                  <div className="hotel-details-horizontal">
                    <h5 className="hotel-name">{hotel.name}</h5>
                    <div className="hotel-location">{hotel.name}</div>

                    {/* Display selected amenities */}
                    <div className="hotel-features">
                      <span className="feature">Parking Facility</span>
                      <span className="feature">Free Wifi</span>
                      <span className="feature">Reception amenities</span>
                      <span className="more-features"> more</span>
                    </div>

                    {/* Pricing details */}
                    <div className="price-section">
                      <span className="current-price">{hotel.price}</span>
                      <span className="original-price">
                        ₹{hotel.originalPrice}
                      </span>
                      <span className="discount">({hotel.discount}0% off)</span>
                    </div>
                    <div className="card-actions">
                      <button
                        className="btn btn-sm btn-primary rounded py-2 px-4 btn-sm-custom"
                        onClick={() => handleShowModal(hotel)}
                      >
                        {hotel.yellowbtn}
                      </button>
                      <a
                        className="btn btn-sm btn-dark rounded py-2 px-4 btn-sm-custom"
                        href={getWhatsAppLink(hotel.name)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hotel.darkbtn}
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No hotels available for {cityName}</p>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalContent.images && modalContent.images.length > 0 && (
            <Slider {...sliderSettings}>
              {modalContent.images.map((image, idx) => (
                <div key={idx} className="slider-image-container">
                  <img
                    className="img-fluid mb-3 medium-image"
                    src={image}
                    alt={`Slide ${idx + 1}`}
                  />
                </div>
              ))}
            </Slider>
          )}
          {modalContent.images && modalContent.images.length === 0 && (
            <p>No images available.</p>
          )}
          <div className="description-section mt-3">
            <h6>Description</h6>
            <p>{modalContent.description}</p>
          </div>
          {modalContent.amenities && (
            <div className="mt-3 amenities-section">
              <h6>Amenities</h6>
              <div className="amenities-list">
                {Object.keys(modalContent.amenities).map((key, index) => (
                  <div key={index} className="amenity-item">
                    <h6>{key.replace(/([A-Z])/g, " $1")}</h6>
                    <ul className="amenity-subitems">
                      {modalContent.amenities[key].map((amenity, idx) => (
                        <li key={idx} className="amenity-text">
                          {amenity}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            href={getWhatsAppLink(modalContent.name)}
            target="_blank"
          >
            Book Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CityHotels;
