import React, { useState } from "react";
import { Link } from "react-router-dom";
import SocialIcons from "./SocialIcons";

export default function Header() {
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const navList = [
    { path: "/", text: "Home" },
    { path: "/about", text: "About" },
    {
      text: "Rooms",
      dropdown: [
        { path: "/hotel", text: "Hotel" },
        { path: "/homestay", text: "Homestay" },
        { path: "/holiday", text: "Holiday" },
      ],
    },
    { path: "/listyourproperty", text: "List Your Property" },
    { path: "/contact", text: "Contact" },
  ];

  const handleMouseEnter = (text) => {
    if (window.innerWidth > 991.98) setActiveDropdown(text);
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 991.98) setActiveDropdown(null);
  };

  const handleDropdownClick = (text) => {
    if (window.innerWidth <= 991.98)
      setActiveDropdown(activeDropdown === text ? null : text);
  };

  return (
    <div className="container-fluid bg-dark px-0">
      <div className="row gx-0">
        <div className="col-lg-3 bg-dark d-none d-lg-block">
          <Link
            to="/"
            className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center"
          >
            <img
              src="../assets/img/logo.png"
              alt="Logo"
              className="logo-bg"
              style={{
                backgroundColor: "#fff",
                padding: "5px",
                width: "75px",
                height: "75px",
              }}
            />
          </Link>
        </div>
        <div className="col-lg-9">
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
            <Link to="/" className="navbar-brand d-block d-lg-none">
              <img
                src="../assets/img/logo.png"
                alt="Logo"
                style={{
                  backgroundColor: "#fff",
                  padding: "5px",
                  width: "75px",
                  height: "75px",
                }}
              />
            </Link>
            <button
              className="navbar-toggler"
              onClick={() => setNavbarCollapse(!navbarCollapse)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`${
                navbarCollapse ? "navbar-collapse show" : "collapse navbar-collapse"
              } justify-content-around`}
            >
              <div className="navbar-nav mr-auto py-0">
                {navList.map((item, index) =>
                  item.dropdown ? (
                    <div
                      className="nav-item dropdown"
                      key={index}
                      onMouseEnter={() => handleMouseEnter(item.text)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleDropdownClick(item.text)}
                    >
                      <span
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-toggle="dropdown"
                      >
                        {item.text}
                      </span>
                      <div
                        className={`dropdown-menu rounded-0 m-0 ${
                          activeDropdown === item.text ? "show" : ""
                        }`}
                      >
                        {item.dropdown.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.path}
                            className="dropdown-item"
                            onClick={() => setNavbarCollapse(false)}
                          >
                            {subItem.text}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Link
                      key={index}
                      to={item.path}
                      className="nav-item nav-link"
                      onClick={() => setNavbarCollapse(false)}
                    >
                      {item.text}
                    </Link>
                  )
                )}
              </div>
              <SocialIcons />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
