import React from "react";
import City from "../home/city";
import Carousel from "./Carousel";

import Book from "./Book";
import About from "./About";
import Rooms from "./Rooms";
import Sliders from "./Slider";
import Teams from "./Team";
import OffersPage from "./OffersPage";
import MediaFeature from "./MediaFeature";
import Newsletter from "./Newsletter";



export default function Home() {
  return (
    <>
     <City />
      <Carousel />
      <Book />
      <OffersPage />
      <About />
      <Rooms />
      <Sliders />
      <Teams />
      <MediaFeature />
      <Newsletter />
    </>
  );
}
