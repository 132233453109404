import React, { useState } from "react";
import './LandingPage.css';
import { Modal, Button } from 'react-bootstrap';
import Heading from "../components/common/Heading";

export default function ListYourProperty() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Heading heading="LIST YOUR PROPERTY" title="Home" subtitle="List Your Property" />
      <div className="content">
        <div className="container my-5">
          <div className="row align-items-center">
            {/* Left Side - Headings */}
            <div className="col-md-6">
              <h1 className="text-dark">Looking to Grow your business </h1>
              <h1 className="text-dark">Just "SAY"</h1>
              <h1 className="text-dark">Partner with us to get benefits from hospitality business professionals</h1>
              <div className="mt-4">
                <a href="#" className="text-primary font-weight-bold" onClick={handleShow}>
                  <div className="animate-bounce">
                    <i className="fas fa-arrow-down fa-lg"></i>
                  </div>
                  Our Silent Features
                </a>
              </div>
            </div>

            {/* Right Side - Image */}
            <div className="col-md-6 text-center">
              <img
                src="..\assets\img\logo.png"
                alt="Example"
                className="img-fluid"
              />
            </div>
          </div>

          {/* Join Button */}
          <div className="fixed-bottom text-center mb-5">
            <Button
              className="btn btn-primary btn-lg"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfYVjOkbyMdfh_e_BOD6XbGeW6P-3olpW4HIVEw9fsuTrsh6A/viewform"
              target="_blank"
              style={{ width: '200px', height: '60px', fontSize: '1.5rem' }}
            >
              Join
            </Button>
          </div>

          {/* Terms & Conditions Modal */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-primary">Our Silent Features</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p><strong>Feedback Management:</strong> Guest Feedback is of the utmost importance for any Property. Hence, feedback management is taken care of by the Company.</p>
              <p><strong>Online (OTA, App) and Offline Presence:</strong> The company takes entire responsibility right from listing the property on major OTAs, making its presence in the online market, managing the OTAs as well as making the offline presence by word of mouth, our preferred partner, and travel agents.</p>
              <p><strong>Social Media Marketing:</strong> Social Media is the Key. So to get to the right set of Locks, the company promotes each property on all Social Media Channels which helps bring traction.</p>
              <p><strong>Inventory Management across Channel:</strong> Time is crucial in the fast-changing world. Hence, managing the inventory across all channels can be hectic. The company with an experienced team is equipped to handle the inventories smoothly.</p>
              <p><strong>Google Ads, Google Hotels, and Google Page Management:</strong> Google is the ultimate king where one can find anything & everything. Therefore, managing each property's google page, and running google ads for better reach, is done by the company without any extra cost.</p>
              <p><strong>Help in Operation:</strong> Running a property's operations smoothly is the biggest challenge. Herein, the company helps each property owner to overcome the challenge if required by providing training to your staff, helping in hiring the right set of people, and providing guidance for inventory updation. For promotion, proper photoshoots are also done by the team.</p>
              <p><strong>24x7 Call Center Support:</strong> To run the business smooth, call center support plays a crucial role. It can help with questions about bookings or services, issues with reservations or cancellations, and assist the guest with location, etc.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
