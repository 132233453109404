import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonHeading from "../common/CommonHeading";
import { facility, roomItems } from "../data/Data";

export default function Rooms() {
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (item) => {
    setModalContent(item);
    setShowModal(true);
  };

  const renderDescription = (description) => {
    const maxLength = 50;
    return description.length > maxLength
      ? `${description.slice(0, maxLength)}...`
      : description;
  };

  const getWhatsAppLink = (name) => {
    const phoneNumber = "9382167169";
    const message = `I would like to inquire about booking a room at ${name}.`;
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <CommonHeading
            heading="Our Rooms"
            title="Rooms"
            subtitle="Explore Our"
          />
          <div className="row g-4">
            {roomItems.map((item, index) => (
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
                key={index}
              >
                <div className="room-item shadow rounded overflow-hidden zoom-effect">
                  <div className="position-relative">
                    <img className="img-fluid" src={item.img} alt="Room" />
                    <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                      {item.price}
                    </small>
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{item.name}</h5>
                    </div>
                    <div className="d-flex mb-3">
                      {facility.map((fac, idx) => (
                        <small className="border-end me-3 pe-3" key={idx}>
                          {fac.icon} {fac.quantity} {fac.facility}
                        </small>
                      ))}
                    </div>
                    <p
                      className="text-body mb-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShowModal(item)}
                    >
                      {renderDescription(item.description)}
                    </p>
                    <div className="d-flex justify-content-between btn-container">
                      <a
                        className="btn btn-sm btn-primary rounded py-2 px-4 btn-sm-custom"
                        href="#"
                        onClick={() => handleShowModal(item)}
                      >
                        {item.yellowbtn}
                      </a>
                      <a
                        className="btn btn-sm btn-dark rounded py-2 px-4 btn-sm-custom"
                        href={getWhatsAppLink(item.name)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.darkbtn}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalContent.images && modalContent.images.length > 0 && (
            <Slider {...sliderSettings}>
              {modalContent.images.map((image, idx) => (
                <div key={idx} className="slider-image-container">
                  <img className="img-fluid mb-3 medium-image" src={image} alt={`Slide ${idx + 1}`} />
                </div>
              ))}
            </Slider>
          )}
          {modalContent.images && modalContent.images.length === 0 && (
            <p>No images available.</p>
          )}
          <div className="description-section mt-3">
            <h6>Description</h6>
            <p>{modalContent.description}</p>
          </div>
          {modalContent.amenities && (
            <div className="mt-3 amenities-section">
              <h6>Amenities</h6>
              <div className="amenities-list">
                {Object.keys(modalContent.amenities).map((key, index) => (
                  <div key={index} className="amenity-item">
                    <h6>{key.replace(/([A-Z])/g, " $1")}</h6>
                    <ul className="amenity-subitems">
                      {modalContent.amenities[key].map((amenity, idx) => (
                        <li key={idx} className="amenity-text">
                          {amenity}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            href={getWhatsAppLink(modalContent.name)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Book Now
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add custom styles */}
      <style jsx>{`
        .modal-dialog {
          max-width: 900px;
          margin: 1.75rem auto;
        }

        .description-section {
          margin-top: 70px;
        }

        .slider-image-container {
          padding: 0 15px;
        }

        .medium-image {
          width: 90%;
          height: auto;
          margin: 0 auto;
        }

        .amenities-list {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }

        .amenity-item {
          margin-bottom: 30px;
        }

        .amenity-subitems {
          margin-top: 10px;
          padding-left: 20px;
        }

        .amenity-text {
          display: list-item;
          font-size: 14px;
          color: black;
          margin-bottom: 5px;
        }

        /* Zoom-in effect on room cards */
        .room-item {
          transition: transform 0.3s ease;
        }

        .room-item:hover {
          transform: scale(1.05);
        }
      `}</style>
    </>
  );
}
