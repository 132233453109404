// Teams.js
import React from "react";
import { socialIcons, team } from "../data/Data";
import CommonHeading from "../common/CommonHeading";

export default function Teams() {
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <CommonHeading heading="Meet ours" subtitle="Founding" title="Team" />
          <div className="row g-3 justify-content-center">
            {team.map((item, index) => (
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                <div className="rounded shadow overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src={item.image} alt="img" />
                    <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                      {socialIcons.map((val, iconIndex) => {
                        const socialLink = item.socials[val.name.toLowerCase()];
                        return (
                          socialLink && (
                            <a
                              className="btn btn-square btn-primary mx-1"
                              href={socialLink}
                              key={iconIndex}
                              target="_blank" // Open link in a new tab
                              rel="noopener noreferrer" // Security measure for external links
                            >
                              {val.icon}
                            </a>
                          )
                        );
                      })}
                    </div>
                  </div>
                  <div className="text-center p-4 mt-3">
                    <h5 className="fw-bold mb-0">{item.name}</h5>
                    <small>{item.designation}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
