import React from "react";
import Heading from "../components/common/Heading";

export default function Homestay() {
  return (
    <>
      <Heading heading="HOMESTAY" title="Home" subtitle="Homestay" />
      <div className="container">
      <p className="h1 text-center text-primary text-uppercase">Coming Soon </p>
      </div>
    </>
  );
}
