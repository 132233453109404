import React from "react";
import CommonHeading from "../components/common/CommonHeading";

export default function HelpAndSupport() {
  return (
    <div className="container help-support-container">
      <CommonHeading heading="Help & Support" />
      <div className="help-support-content">
        <p>
          For any issues or immediate assistance, guests can reach our 24/7 helpline at <strong>9382167169</strong>, where our dedicated team is ready to provide support and resolve any concerns promptly.
        </p>
        <p>
          When guests encounter issues during their stay at a hotel/homestay booked via Say Rooms & Homes, we ensure immediate and effective solutions:
        </p>
        <ul>
          <li>
            <strong>Cleanliness Concerns:</strong> Arrange for immediate re-cleaning of the area in question or offer an alternative accommodation if necessary, ensuring cleanliness meets expectations.
          </li>
          <li>
            <strong>Check-in or Check-out Challenges:</strong> Facilitate smooth check-in and check-out processes with clear instructions, flexible timings when possible, and assistance with luggage handling.
          </li>
          <li>
            <strong>Booking Conflicts:</strong> Resolve conflicts swiftly by offering alternative accommodation options or negotiating suitable arrangements to accommodate changes in reservation details (except Natural Calamity, Acts of God, Government Imposed Lockdown, Strike, War).
          </li>
          <li>
            <strong>Emergency Situations:</strong> Maintain 24/7 emergency contact support for guests, ensuring immediate response and coordination with local authorities or medical services if required.
          </li>
        </ul>
        <p>
          At Say Rooms & Homes, our commitment to exceptional customer service means that we are always ready to address any challenges guests may face during their stay, ensuring their comfort, satisfaction, and peace of mind.
        </p>
        <p>
          <strong>Helpline Number (24/7):</strong> +919382167169
        </p>

      </div>
    </div>
  );
}
