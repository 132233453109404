import React from 'react';
import { Card } from 'react-bootstrap';
import { NewsItem } from '../data/Data'; 
import CommonHeading from "../common/CommonHeading";

const MediaCard = ({ item }) => {
  return (
    <Card className="card-hover" style={{ width: '18rem', margin: '10px' }}>
      <Card.Img variant="top" src={item.image} />
      <Card.Body className="d-flex flex-column">
        <Card.Title>{item.title}</Card.Title>
        <Card.Text className="text-muted">{item.source}</Card.Text>
        <div className="mt-auto">
          <a href={item.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
            Read more
          </a>
        </div>
      </Card.Body>
    </Card>
  );
};

const MediaFeature = () => {
  return (
    <>
      <CommonHeading heading="articles" subtitle="Media" title="Feature" />

      <div className="d-flex flex-wrap justify-content-center">
        {NewsItem.map((item, index) => (
          <MediaCard key={index} item={item} />
        ))}
      </div>
      <style jsx>{`
  .card-hover {
  transition: transform 0.3s ease;
}

.card-hover:hover {
  transform: scale(1.05); /* Adjust scale as needed */
}
`}</style>
    </>
  );
};

export default MediaFeature;
