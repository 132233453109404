import React from "react";
import "./css/style.css";
import "./css/bootstrap.min.css";
import "./css/animate.min.css"; // Removed duplicate imports
import "./App.css";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  Home,
  City,
  CityHotels,
  Booking,
  OffersPage,
  AboutUs,
  Contact,
  PageNotFound,
  Room,
  Team,
  MediaFeature,
  Testimonial,
  Hotel,
  Homestay,
  Holiday,
  ListYourProperty,
  HelpAndSupport,
  PrivacyPolicy,
  TermsAndConditions,
  Newsletter,
} from "./pages/index";

const App = () => {
  return (
    <Router>
      {/* Moved Header inside Router */}
      <Header />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/city" element={<City />} />
          <Route path="/city/:cityName" element={<CityHotels />} /> {/* Child page */}
          <Route path="/offerspage" element={<OffersPage />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/team" element={<Team />} />
          <Route path="/mediafeature" element={<MediaFeature />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/rooms" element={<Room />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/homestay" element={<Homestay />} />
          <Route path="/listyourproperty" element={<ListYourProperty />} />
          <Route path="/holiday" element={<Holiday />} />
          <Route path="/help-and-support" element={<HelpAndSupport />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> {/* Updated for consistency */}
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/*" element={<PageNotFound />} /> {/* Kept as last route */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
